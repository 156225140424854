// import '@babel/polyfill';
import Rails from 'rails-ujs';

import LessonCourseEnquiries from './lesson_course_enquiries';
import RefundForms from './refund_forms';
import './pollyfills';
import './load_service_worker';
import './font_observer';
import './global';
import './async_modules';

import './contact';
import './carousel';
import './center_locator';
import './dynamic_pages';
import './homepage';
import PrimaryNav from './primary-nav';
import FaqMultiple from './faq-multiple-category';
import TemporarilyUnavailable from './temporarily-unavailable';
import VenueSearchResultsLoader from './venue_search_results_loader';
import VenueSelector from './venue_selector';
import CookieSettings from './cookie_settings';
import Bmi from './questionnaire/bmi';
import CheckBoxes from './questionnaire/check_boxes';
import QuestionnaireContactFields from './questionnaire/contact_fields';
import Range from './questionnaire/range';
import RefundFormMembershipNumber from './refund_form/membership_number';
import RefundValue from './refund_form/refund_value';
import RefundBankInputs from './refund_form/bank_inputs';
import RefundDateInputs from './refund_form/date_inputs';
import BannerCarousel from './banner_carousel';
import CenterLocator from './center_locator_book_activity';
import SwimForm from './swim_form'
import lessonEnquiryForm from './lesson_enquiry_form';
import venueHireForm from './venue_hire_form';
import refugeeForm from './refugee_form';
import PhotoComp from './photo_comp';
import alerts from './alerts';
import replacementLessons from './replacement_lessons';
import lvMemberships from './lv_memberships';
import timetable from './timetable';
import VideoBarModal from './video_bar_modal';
import lvBirthdays from './lv_birthdays';
import newShare from './news_link_share';
import bmi from './bmi';
import selfRefDatePicker from './datepicker/self-referral';
import libTimetable from './lib_table';
import supportedMembershipForm from './contact/lessons_courses/supported_membership_forms'
import PupilRefundForm from './pupil_refund_request';

(() => {
  // Intialise Rails UJS
  Rails.start();
})();

(() => {
  PrimaryNav();
})();

(() => {
  FaqMultiple();
})();

(() => {
  LessonCourseEnquiries();
})();

(() => {
  RefundForms();
})();

(() => {
  supportedMembershipForm();
})();

(() => {
  TemporarilyUnavailable();
})();

(() => {
  VenueSearchResultsLoader();
})();

(() => {
  VenueSelector();
})();

(() => {
  CookieSettings();
})();

(() => {
  Bmi();
})();

(() => {
  CheckBoxes();
})();

(() => {
  QuestionnaireContactFields();
})();

(() => {
  Range();
})();

(() => {
  RefundFormMembershipNumber();
})();

(() => {
  RefundValue();
})();

(() => {
  RefundBankInputs();
})();

(() => {
  RefundDateInputs();
})();

(() => {
  BannerCarousel();
})();

(() => {
  CenterLocator();
})();

(() => {
  SwimForm();
})();

(() => {
  lessonEnquiryForm();
})();

(() => {
  venueHireForm();
})();

(() => {
  refugeeForm();
})();

(() => {
  PhotoComp();
})();

(() => {
  alerts();
})();

(() => {
  replacementLessons();
})();

(() => {
  lvMemberships();
})();

(() => {
  timetable();
})();

(() => {
  VideoBarModal();
})();

(() => {
  lvBirthdays();
})();

(() => {
  newShare();
})();

(() => {
  bmi();
})();

(() => {
  selfRefDatePicker();
})();

(() => {
  libTimetable();
})();

(() => {
  PupilRefundForm();
})();


window.Rails = Rails;
window.RefundForms = RefundForms;
